import { useEffect, useState } from 'react'

const useCoinPriceDetails = (num) => {
    const [coinPriceDetails, setCoinPriceDetails] = useState([])
    const url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=${num}&page=1&sparkline=true`

    useEffect(() => {
        fetch(url)
            .then((res) => res.json())
            .then((data) => setCoinPriceDetails(data))
    }, [url])
    return [coinPriceDetails]
}

export default useCoinPriceDetails
