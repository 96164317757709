import React from "react";

import ManageAllNews from "./ManageAllNews";

const DashBoard = () => {
  return (
    <div>
      <ManageAllNews />
    </div>
  );
};

export default DashBoard;
