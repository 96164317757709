import React from "react";
import { useNavigate } from "react-router-dom";
import useCoinPriceDetails from "../../hooks/useCoinPriceDetails";
import CoinDetailsTitle from "./CoinDetailsTitle";

const Title = () => {
  const [coinPriceDetails] = useCoinPriceDetails(5);
  const navigate = useNavigate();

  return (
    <section className="bg-[#253137] px-5 ">
      <div className=" max-w-7xl mx-auto pt-4 md:flex items-center justify-between ">
        <div onClick={() => navigate("/")} className="cursor-pointer">
          <h1 className="text-4xl  text-[#FABF2C]">কয়েন বার্তা</h1>
          <h3 className="text-sm text-white">বাংলায় ব্লকচেইন জানুন</h3>
        </div>
        <div
          onClick={() => navigate("/coin-details")}
          className=" cursor-pointer gap-2 md:gap-5 items-center py-4 flex"
        >
          <CoinDetailsTitle coinPriceDetails={coinPriceDetails} />
        </div>
      </div>
    </section>
  );
};

export default Title;
