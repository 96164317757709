import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

export const ServerUrlContext = React.createContext()

const isProduction = process.env.NODE_ENV === 'production'
//const serverUrl = 'http://localhost:4000'

const serverUrl = 'https://coinbarta.com/backend'

// const serverUrl = isProduction
//   ? 'https://coinbarta.com/backend'
//   : 'https://coinbarta.com/backend'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ServerUrlContext.Provider value={serverUrl}>
        <App />
      </ServerUrlContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()


