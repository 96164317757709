import React from 'react'

const CoinDetailsTitle = ({ coinPriceDetails }) => {
    return (
        <>
            {coinPriceDetails.map((coinPriceDetail, index) => {
                const { symbol, price_change_percentage_24h, current_price } =
                    coinPriceDetail

                return (
                    <div key={index} className="flex gap-5">
                        <div>
                            <h3 className="text-[#FABF2C] uppercase">
                                {symbol}
                            </h3>
                            <h3 className="text-white">
                                ${parseInt(current_price)}
                            </h3>
                            <p
                                className={
                                    price_change_percentage_24h < 0
                                        ? 'text-red-500'
                                        : 'text-green-500'
                                }
                            >
                                {' '}
                                {price_change_percentage_24h.toFixed(2)}
                            </p>
                        </div>
                        <div className=" bg-gray-300 w-[1px] rounded-full h-16"></div>
                    </div>
                )
            })}
        </>
    )
}

export default CoinDetailsTitle
