import React, { useEffect, useState } from "react"
import useCoinPriceDetails from "../hooks/useCoinPriceDetails"
import Nav from "../pages/navbar/Nav"
import ReactPaginate from "react-paginate"
import Spinner from "./Spinner"

const CoinDetails = () => {
  const [coinPriceDetails] = useCoinPriceDetails(250)

  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 25
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(coinPriceDetails.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(coinPriceDetails.length / itemsPerPage))
  }, [itemOffset, coinPriceDetails])

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % coinPriceDetails.length

    setItemOffset(newOffset)
  }

  return (
    <>
      <Nav />
      {currentItems.length === 0 ? (
        <Spinner />
      ) : (
        <section className='max-w-7xl mx-auto my-20'>
          <div className='overflow-x-auto relative'>
            <table className='w-full text-sm text-left text-gray-500 '>
              <thead className='text-base text-gray-700 uppercase bg-gray-200  '>
                <tr>
                  <th scope='col' className='py-3 px-6'>
                    #
                  </th>
                  <th scope='col' className='py-3 px-6'>
                    Coin
                  </th>
                  <th scope='col' className='py-3 px-6 text-right'>
                    Price in USD
                  </th>
                  <th scope='col' className='py-3 px-6 text-right'>
                    24h Change
                  </th>

                  <th scope='col' className='py-3 px-6 text-right'>
                    24h Volume
                  </th>
                  <th scope='col' className='py-3 px-6 text-right'>
                    Mkt Cap
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((coinPriceDetail, index) => {
                  const {
                    image,
                    name,
                    symbol,
                    current_price,
                    price_change_24h,
                    total_volume,
                    market_cap,
                    sparkline_in_7d,
                  } = coinPriceDetail
                  const price = sparkline_in_7d.price

                  const newPrice = []
                  for (let i = 0; i < price.length; i++) {
                    const element = price[i]
                    const pr = element * 1000000
                    const singlePrice = {
                      pv: pr,
                    }
                    newPrice.push(singlePrice)
                  }

                  return (
                    <tr
                      key={index}
                      className='bg-white border-b hover:bg-gray-50 '
                    >
                      <td className='py-4 px-6'>{index + itemOffset + 1}</td>
                      <td className='py-4 px-6'>
                        <div className='flex gap-3 items-center'>
                          <img className='w-8 h-8' src={image} alt='' />
                          <div>
                            <p>{name}</p>
                            <p className='uppercase text-xs text-gray-400'>
                              {symbol}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className='py-4 px-6 text-right'>${current_price}</td>
                      <td className='py-4 px-6 text-right '>
                        {price_change_24h}
                      </td>

                      <td className='py-4 px-6 text-right'>{total_volume}</td>
                      <td className='py-4  px-6 text-right'>$ {market_cap}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </section>
      )}
      <div className=' mt-5 max-w-sm mx-auto'>
        <>
          <ReactPaginate
            breakLabel='...'
            nextLabel=' >'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel='< '
            renderOnZeroPageCount={null}
            containerClassName='pagination'
            pageLinkClassName='page-num'
            previousLinkClassName='page-num'
            nextLinkClassName='page-num'
            activeLinkClassName='active'
          />
        </>
      </div>
    </>
  )
}

export default CoinDetails
