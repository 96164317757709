import React from 'react'
import Nav from '../navbar/Nav'

const About = () => {
  return (
    <>
      <Nav />
      <div className='max-w-7xl mx-auto'>
        <h2 className='text-3xl px-4  md:px-0 font-bold text-gray-600 mt-8 mb-3'>
          আমাদেরকে জানুন
        </h2>
        <section className='min-h-[70vh] px-4 md:px-0 md:flex mx-auto justify-left items-start gap-8'>
          <div className='flex flex-col justify-start gap-4  my-4'>
            <p>
              প্রতিনিয়ত বিশ্ব এগিয়ে চলছে উদ্ভাবনী শক্তি দিয়। প্রযুক্তিতে তথা
              সফটওয়্যার দুনিয়ায় এই শক্তির প্রভাব বেশি আছে এতে কোনো সন্দেহ নাই।
              এই শিল্পে নতুন নতুন প্রযুক্তির আবিষ্কার বিশ্বকে করে তুলেছে
              বৈচিত্রময় এবং সম্ভাবনাময় ভবিষ্যত। ব্লকচেইন এমনি একটি টেকনোলজি বা
              প্রযুক্তি । লেজার ট্রানজেকশনে নিরাপত্তা দেয়ার ক্ষেত্রে তার জুড়ি
              মেলা ভার। এ করণে WEB3 ব্লকচেইন টেকনোলজি ব্যবহার করে। দ্রুত
              পরিবর্তিত টেকনোলজিকাল বিশ্বে আমাদেরকে খুব ভাল করতে হলে জানার কোনো
              বিকল্প নাই। আমরা বিশ্বাস করি প্রযুক্তিগত জ্ঞান সমৃদ্ধ করার সুযোগ
              সকলের পাওয়া উচিত৷ আর সেটা যদি হয় নিজের ভাষায় তাহলে জানতে পারা
              অনেক সহজ হয়ে যায়। স্থানীয় ভাষায় এটাকে উচ্চতর পর্যায়ে নিয়ে যেতে
              চান আপনি ?
            </p>
            <p>
              <b>"কয়েন বার্তা"</b> এমন একটি ওয়েবসাইট যেখানে আপনি বাংলা ভাষায়
              ব্লকচেইন সম্বন্ধে জানতে পারবেন । দেশ এবং দেশের বাহিরের সংবাদও
              পাবেন এখানে। থাকছে টেক বিশেষজ্ঞের মতামত এবং ইন্টারভিউ।
            </p>
            <p>
              এমন একটি সাইট উপস্থাপনের দায়িত্ব নিয়েছে{' '}
              <a
                href='https://yafitechbd.com/'
                target='_blank'
                rel='noreferrer'
                className='font-bold text-black'
              >
                Yafi Tech
              </a>
              । CEO আবু ইউসুফ (MSc, Web Engineering, TUC, Germany; BSc, CSE ,
              CUET) সামনে থেকে নেতৃত্ব দিচ্ছেন যার রয়েছে টেকনোলজিতে অনেক বছরের
              অভিজ্ঞতা। আছেন অভিজ্ঞ সাংবাদিক সালেহ নোমান,(সম্পাদক, কয়েন বার্তা
              ), এবং কিছু সম্ভাবনাময় তরুণ ।
            </p>
            <p className='font-bold mt-7'>
              ঠিকানা: কয়েন বার্তা: <br /> রুম ৪০৪, লুসাই ভবন, ৫ CDA C/A, মোমিন
              রোড , (চেরাগী স্কোয়ার) <br /> কোতোয়ালি , চট্টগ্রাম
            </p>
          </div>

          <p className='font-bold'>
            <span className='mb-3 inline-block'> মানচিত্র:</span>
            <iframe
              className=' w-[360px] md:w-[600px]'
              title='map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.2820300040976!2d91.83178126481846!3d22.342977335301494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad278bb6eceeef%3A0x9d2b21fb1f4fb2a9!2z4Kam4KeI4Kao4Ka_4KaVIOCmqOCnn-CmvuCmrOCmvuCmguCmsuCmvg!5e0!3m2!1sen!2sde!4v1663522360652!5m2!1sen!2sde'
              // width='600'
              height='450'
              style={{ border: '0', paddingBottom: '50px' }}
              allowfullscreen=''
              loading='lazy'
              referrerpolicy='no-referrer-when-downgrade'
            ></iframe>
          </p>
        </section>
      </div>
    </>
  )
}

export default About
