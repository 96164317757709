import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTableCellsLarge } from '@fortawesome/free-solid-svg-icons'

import React from 'react'
import { Link } from 'react-router-dom'

const NewsCard = ({ news }) => {
  return (
    <Link
      to={`/news/${news._id}`}
      
      className='max-w-xs bg-white rounded border border-gray-200 shadow-md relative hover:bg-gray-50 duration-300 hover:shadow-lg hover:-translate-y-1'
    >
      <Link to={`/news/${news._id}`} >
        <img
          className='rounded-t max-h-44 w-full min-h-[176px]'
          src={news?.images[0]}
          alt=''
        />
      </Link>

      <div className='p-5 pb-24'>
        <Link to={`/news/${news._id}`} >
          <h5 className='mb-2 text-lg hover:underline font-bold tracking-tight text-gray-900 '>
            {news?.title}
          </h5>
        </Link>
        <div className='w-full flex flex-col justify-between items-start gap-3 mt-6 absolute bottom-4'>
          <p className='flex justify-start items-center gap-2'>
            <FontAwesomeIcon icon={faTableCellsLarge} />
            <span>{news?.category}</span>
          </p>
          <p className='flex justify-start items-center gap-2'>
            <FontAwesomeIcon icon={faClock} />
            <span>{news?.createDate.split(', ')[0]}</span>
          </p>
        </div>
      </div>
    </Link>
  )
}

export default NewsCard
