import React, { useContext } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { ServerUrlContext } from '../..'
import { CatagoriesContext } from '../../App'
import useCategory from '../../hooks/useCategory'

const Nav = () => {
  const serverUrl = useContext(ServerUrlContext)
  // const [categories] = useCategory(serverUrl)
  const navigate = useNavigate()
  const categories = useContext(CatagoriesContext)
  const filtered = categories?.filter((category) => category.publish === true)

  const handleSearch = () => {
    console.log('object')
    const searchField = document.getElementById('search-field')
    const searchValue = searchField.value
    navigate(`/searchResult/${searchValue}`)
  }
  return (
    <section className='bg-[#FABF2C] px-4'>
      <div className=' max-w-7xl mx-auto'>
        <div className='navbar p-0'>
          <div className='navbar-start w-3/4'>
            <div className='dropdown'>
              <label tabIndex='0' className='btn btn-ghost lg:hidden'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h8m-8 6h16'
                  />
                </svg>
              </label>

              <ul
                tabIndex='0'
                className='menu menu-compact dropdown-content mt-3 shadow bg-base-300 rounded-box w-52'
              >
                <li>
                  <Link to='/' className='text-lg py-0'>
                    হোমপেজ{' '}
                  </Link>
                </li>
                {filtered?.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to={`/category-news/${item.name}`}
                        className='text-lg py-0'
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
                <li>
                  <Link to='/coin-details' className='text-lg py-0'>
                    কয়েনের দাম{' '}
                  </Link>
                </li>
                <li>
                  <Link to='/about' className='text-lg py-0'>
                    আমাদেরকে জানুন
                  </Link>
                </li>
              </ul>
            </div>
            {/* nav for lg device */}
            <div className=' hidden lg:flex'>
              <ul className='menu-horizontal flex-wrap gap-x-8 gap-y-2 py-2'>
                <li className='w-max'>
                  <Link
                    to='/'
                    className='text-lg py-0 hover:text-gray- font-bold duration-300'
                  >
                    হোমপেজ{' '}
                  </Link>
                </li>
                {filtered?.map((item, index) => {
                  return (
                    <li key={index} className='w-max'>
                      <Link
                        to={`/category-news/${item.name}`}
                        className='text-lg py-0 hover:text-gray- font-bold duration-300'
                      >
                        {item.name}
                      </Link>
                    </li>
                  )
                })}
                <li className='w-max'>
                  <Link
                    to='/coin-details'
                    className='text-lg py-0 hover:text-gray- font-bold duration-300'
                  >
                    কয়েনের দাম{' '}
                  </Link>
                </li>
                <li className='w-max'>
                  <Link
                    to='/about'
                    className='text-lg py-0 hover:text-gray- font-bold duration-300'
                  >
                    আমাদেরকে জানুন
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <form onPress={handleSearch} className='navbar-end w-1/4'>
            <input
              required
              type='text'
              id='search-field'
              placeholder='নিউজ সার্চ'
              className='py-2 rounded-l-lg pl-3'
            />
            <button
              onClick={handleSearch}
              className='bg-slate-200 rounded-r-lg  p-2'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-5'
                fill='#fff'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Nav
