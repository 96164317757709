import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import countapi from 'countapi-js';
import { ServerUrlContext } from '..';

const WebVisitorCounter = () => {
    let serverUrl = useContext(ServerUrlContext)
    const [visitor, setVisitor] = useState(0)
    const url =
        `${serverUrl}/api/v1/counter/get-counter`
    useEffect(() => {
        // countapi.visits('global').then((result) => {
        //     setVisitor(result.value);
        //   }).catch((error) => console.log(error));
        fetch(url)
            .then((res) => res.json())
            .then((data) => setVisitor(data.result[0].count))
    }, [])


    return (
        <div>
            {visitor && (
                <p className="text-gray-100 mt-2 text-lg">
                    <FontAwesomeIcon icon={faEye} />
                    {'  '}
                    {visitor}
                </p>
            )}
        </div>
    )
}

export default WebVisitorCounter
