import React, { useContext, useEffect, useState } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import NewsContentEditor from "../../components/NewsContentEditor";
import useCategory from "../../hooks/useCategory";
import { ServerUrlContext } from "../..";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DashBoardNav from "./DashBoardNav";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../components/Spinner";

const UpdateNews = () => {
  const serverUrl = useContext(ServerUrlContext);
  const [content, setContent] = useState("");
  const [errorText, setErrorText] = useState("");
  const [selectedNews, setSelectedNews] = useState({});
  // const [selectedImages, setSelectedImages] = useState([]);
  const [imgUrlOne, setImgUrlOne] = useState();
  const [imgUrlTwo, setImgUrlTwo] = useState();
  const [categories] = useCategory(serverUrl);
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    fetch(`${serverUrl}/api/v1/news/get-single-news?id=${id}`)
      .then((res) => res.json())
      .then((data) => setSelectedNews(data.result));
  }, [serverUrl, id]);

  const publishedCategories = categories.filter(
    (category) => category.publish === true
  );

  const handleSetImg = (file, setImg) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImg(reader.result);
    };
  };

  const handleUpdateNews = (newsData) => {
    const { title, category } = newsData;
    if (!content) {
      return setErrorText("Please Add some content");
    }
    setErrorText("");
    if (selectedNews?.images.length === 0 && !imgUrlOne && !imgUrlTwo) {
      toast.error("Please select an image");
      return;
    }

    const news = { title, category, content, imgUrlOne, imgUrlTwo };
    fetch(`${serverUrl}/api/v1/news/update-news?id=${id}`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(news),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success("Successfully updated news");
          navigate("/manageAll");
          reset();
        }
      });
  };

  const handleRemoveImage = (image) => {
    const { images, ...rest } = selectedNews;
    const newImages = images.filter((item) => item !== image);
    const newData = { ...rest, images: newImages };

    fetch(`${serverUrl}/api/v1/news/delete-image?id=${selectedNews?._id}`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ image }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectedNews(newData);
        console.log(data);
      });
  };

  return (
    <>
      <DashBoardNav />
      {!selectedNews ? (
        <Spinner />
      ) : (
        <div className="flex max-w-7xl mx-auto my-3 justify-center items-center ">
          <div className="card w-full bg-base-100 shadow-xl  pb-5">
            <div className="card-body ">
              <h2 className="text-center m-2 text-2xl font-bold ">
                Update News
              </h2>
              <div className="flex justify-center items-center w-full">
                <form
                  onSubmit={handleSubmit(handleUpdateNews)}
                  className="w-full"
                  action=""
                >
                  <div className="form-control w-full ">
                    <label className="label">
                      <span className="label-text">Title</span>
                    </label>
                    <input
                      {...register("title")}
                      type="text"
                      placeholder="Type here"
                      defaultValue={selectedNews?.title}
                      className="input input-bordered w-full "
                    />
                    <label className="label">
                      {errors.title && (
                        <span className="label-text-alt text-red-500">
                          {errors.title.message}
                        </span>
                      )}
                    </label>
                  </div>
                  <div>
                    <label className="label">
                      <span className="label-text">Content</span>
                    </label>

                    <NewsContentEditor
                      previousContent={selectedNews?.content}
                      setContent={setContent}
                    />
                    {errorText && (
                      <span className="label-text-alt text-red-500">
                        {errorText}
                      </span>
                    )}
                  </div>
                  <div>
                    <div className="form-control w-full max-w-xs">
                      <label className="label">
                        <span className="label-text">Select Category</span>
                      </label>
                      <select
                        {...register("category")}
                        className="select select-bordered"
                      >
                        <option disabled selected>
                          {selectedNews?.category}
                        </option>
                        {publishedCategories?.map((category, index) => (
                          <option key={index}>{category.name}</option>
                        ))}
                      </select>
                      {errors.category && (
                        <span className="label-text-alt text-red-500">
                          {errors.category.message}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <div className="flex flex-col gap-3 my-3">
                        <div>
                          <label className="label">
                            <span className="label-text">
                              Select First Image
                            </span>
                          </label>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) =>
                              handleSetImg(e.target.files[0], setImgUrlOne)
                            }
                          />
                        </div>
                        <div>
                          <label className="label">
                            <span className="label-text">
                              Select Second Image
                            </span>
                          </label>
                          <input
                            type="file"
                            name=""
                            id=""
                            onChange={(e) =>
                              handleSetImg(e.target.files[0], setImgUrlTwo)
                            }
                          />
                        </div>
                        {errors.newsImage && (
                          <span className="label-text-alt text-red-500">
                            {errors.newsImage.message}
                          </span>
                        )}
                      </div>
                      <div>
                        {selectedNews?.images?.map((image) => {
                          return (
                            image && (
                              <div className="relative">
                                <div>
                                  <img
                                    className="h-20 w-40 rounded-lg p-1 "
                                    src={image}
                                    alt=""
                                  />
                                </div>
                                <p
                                  onClick={() => handleRemoveImage(image)}
                                  className="flex justify-center items-center absolute text-red-500 hover:text-gray-700 top-2 right-2 w-[35px] h-[35px] rounded shadow bg-white"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </p>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-outline mt-5">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateNews;
