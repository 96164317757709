import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import WebVisitorCounter from "../../components/WebVisitorCounter";

const Footer = () => {
  return (
    <div className="bg-[#1A1B1D]">
      <footer className="px-4 py-2 max-w-7xl mx-auto  ">
        {/* <hr className="my-6 border-gray-200 sm:mx-aut lg:my-8" /> */}
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <span className="text-sm text-gray-500 sm:text-center ">
              © {new Date().getFullYear()} {""}
              <Link to="http://" className="hover:underline">
                Coin Barta
              </Link>
              . All Rights Reserved.
            </span>
            <div className="hidden md:block  ">
              <WebVisitorCounter />
            </div>
          </div>

          {/* CEO আবু ইউসুফ 
সাংবাদিক সালেহ নোমান,(সম্পাদক, কয়েন বার্তা ) */}

          <div className="text-white">
            <p>
              আবু ইউসুফ{" "}
              <span className="text-[12px] text-slate-400">
                (MSc, Web Engineering, TUC, Germany; BSc, CSE , CUET)
              </span>
            </p>
            <p>CEO, Yafitech</p>
            <p className="mt-2">
              সালেহ নোমান,
              <span className="text-[12px] text-slate-400">সম্পাদক</span>
            </p>
          </div>

          <div className="flex  mt-4 space-x-6 sm:justify-center items-center sm:mt-0">
            <a
              href="https://www.facebook.com/coinbarta"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-100"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.facebook.com/groups/390041106277106"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-gray-100 "
            >
              <FontAwesomeIcon icon={faUserGroup} />
            </a>
            <div className=" md:hidden ">
              <WebVisitorCounter />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
